export function throttle(callback: any, delay: any, options: any = {}) {
  let timeout: any
  let lastState: any
  let currentArgs: any
  const throttled = (state: any, ...args: any) => {
    currentArgs = args
    if (timeout && state === lastState) return
    let leading = options.leading

    if (typeof leading === 'function') {
      leading = leading(state, lastState)
    }
    if ((!timeout || state !== lastState) && leading) {
      callback(state, ...currentArgs)
    }
    lastState = state
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback(state, ...currentArgs)
      timeout = 0
    }, delay)
  }

  throttled._clear = () => {
    clearTimeout(timeout)
    timeout = null
  }
  return throttled
}

export function deepEqual(val1: any, val2: any) {
  if (val1 === val2) return true
  if (typeof val1 === 'object') {
    for (const key in val1) {
      if (!deepEqual(val1[key], val2[key])) {
        return false
      }
    }
    return true
  }
  return false
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function loadScript(id: string, src: string) {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve(0)
      return
    }
    const script = document.createElement('script')
    script.id = id
    script.src = src
    script.async = false // Ensure synchronous execution
    script.onload = () => {
      resolve(1)
    }
    script.onerror = () => {
      reject(new Error(`Failed to load script: ${src}`))
    }
    document.head.appendChild(script)
  })
}
