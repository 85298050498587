<script setup lang="ts">
  import { MenuList } from '@/router/routes'
  import { gsap } from 'gsap'

  const container = ref(null)
  const moreMenus = ref<string[]>([])
  const menuReady = ref(false)
  const menuWidths = ref<{ id: string; width: number }[]>([])
  const currentActiveMenuName = ref('')

  const { width: winWidth } = useDisplay()

  onMounted(() => {
    if (!container.value) return
    const containerEle = container.value as HTMLElement
    const arr = []
    for (const child of containerEle.children) {
      arr.push({ id: child.id.replace('menu-', ''), width: child.clientWidth })
    }
    menuWidths.value = arr
    updateMore()
    menuReady.value = true
  })

  function updateMore() {
    const _menuWidths = [...menuWidths.value]
    const moreBtn = _menuWidths.pop()
    if (!moreBtn) return
    const totalWidth = _menuWidths.reduce((pre, cur) => pre + cur.width, 0)

    const offset = 10 + 160 + 195 // 160: ms-logo, 195 : play free now button
    const menuWidthLimit = winWidth.value - offset
    const list = []
    if (totalWidth > menuWidthLimit) {
      let remainedWidth = totalWidth + moreBtn.width
      while (remainedWidth > menuWidthLimit) {
        const one = _menuWidths.pop()
        if (!one) return
        remainedWidth -= one.width
        list.push(one.id)
      }
    }
    moreMenus.value = list
  }

  watchDebounced(winWidth, () => updateMore(), { debounce: 100, maxWait: 400 })

  onMounted(() => {
    gsap.from('.main-menu-desktop', { opacity: 0, y: '-30%', stagger: 0.1, clearProps: true })
    // const Springer = window.Springer.default
    // const spring = Springer(0.7, 0.8) // tension, wobble
    // gsap.from('.main-menu-desktop', { scale: 0, stagger: 0.15, ease: spring, duration: 1.4 })
  })
</script>

<template>
  <RouterLink to="/"><i-local-ms-logo class="ms-logo" /></RouterLink>
  <div ref="container" class="desktop-menu" :class="{ show: menuReady }">
    <MenuDesktop
      v-for="menu in MenuList.filter((n) => !moreMenus.includes(String(n.name)))"
      :id="String(menu.name)"
      :key="menu.name"
      :active-menu-name="currentActiveMenuName"
      :menu="menu"
      @active-menu="currentActiveMenuName = $event"
    />
    <MenuDesktop
      v-if="moreMenus.length > 0 || !menuReady"
      :active-menu-name="currentActiveMenuName"
      :menu="{
        path: '_',
        name: 'more',
        meta: { menuTitle: 'More' },
        children: MenuList.filter((n) => moreMenus.includes(String(n.name))),
      }"
      @active-menu="currentActiveMenuName = $event"
    />
  </div>
  <PlayNowButton class="play-free-now-btn" />
</template>
<style lang="scss" scoped>
  .ms-logo {
    width: 140px;
    margin-left: 25px;
  }
  .desktop-menu {
    // padding: 4px 0;
    white-space: nowrap;
    // display: inline-block;
    transition: opacity 0.5s;
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }
  .play-free-now-btn {
    margin: 0 25px 0 0;
  }
  @media #{$tablet} {
    .play-free-now-btn {
      font-size: 15px;
    }
  }
</style>
