import { nextTick } from 'vue'
import { throttle } from '@/helpers/utils'

function processOptions(value: any) {
  let options

  if (typeof value === 'function') {
    // Simple options (callback-only)
    options = {
      callback: value,
    }
  } else {
    // Options object
    options = value
  }
  return options
}

export class VisibilityState {
  private readonly el: any
  private observer: IntersectionObserver | null
  private frozen: boolean
  private options: any
  private callback: ((result: any, entry: any) => void) | undefined | any
  private oldResult: false | boolean | undefined

  constructor(el: any, options: any) {
    this.el = el
    this.observer = null
    this.frozen = false
    this.createObserver(options)
  }

  get threshold() {
    return (this.options.intersection && this.options.intersection.threshold) || 0
  }

  createObserver(options: any) {
    if (this.observer) {
      this.destroyObserver()
    }

    // if (this.frozen) return

    this.options = processOptions(options)

    this.callback = (result: any, entry: any) => {
      this.options.callback(result, entry)
      if (result && this.options.once) {
        this.frozen = true
        this.destroyObserver()
      }
    }

    // Throttle
    if (this.callback && this.options.throttle) {
      const { leading } = this.options.throttleOptions || {}

      this.callback = throttle(this.callback, this.options.throttle, {
        leading: (state: any) => {
          return leading === 'both' || (leading === 'visible' && state) || (leading === 'hidden' && !state)
        },
      })
    }

    this.oldResult = false

    this.observer = new IntersectionObserver((entries) => {
      let entry = entries[0]

      if (entries.length > 1) {
        const intersectingEntry = entries.find((e) => e.isIntersecting)

        if (intersectingEntry) {
          entry = intersectingEntry
        }
      }

      if (this.callback) {
        // Use isIntersecting if possible because browsers can report isIntersecting as true, but intersectionRatio as 0, when something very slowly enters the viewport.
        const result = entry.isIntersecting && entry.intersectionRatio >= this.threshold

        if (result === this.oldResult) return
        this.oldResult = result
        this.callback(result, entry)
      }
    }, this.options.intersection)

    // Wait for the element to be in document
    nextTick(() => {
      if (this.observer) {
        this.observer.observe(this.el)
      }
    })
  }

  destroyObserver() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }

    // Cancel throttled call
    if (this.callback && this.callback._clear) {
      this.callback._clear()
      this.callback = null
    }
  }
}
