import GaEvent from './GaEvent'
import GaHover from './GaHover'
import GaSectionView from './GaSectionView'
import GaSwiperDot from './GaSwiperDot'
import Observable from './observable'
import LazyImage from './LazyImage'
// import { visible } from './visible' <-- not use for now
import type { App } from 'vue'

export default {
  install: (app: App) => {
    app.use(GaEvent).use(GaHover).use(GaSectionView).use(GaSwiperDot).use(LazyImage).use(Observable)
  },
}
