import type { RouteRecordRaw } from 'vue-router'
import MainMenu from '@/views/MainMenu.vue'

const supportRoute: RouteRecordRaw = {
  path: '/support',
  name: 'support',
  component: MainMenu,
  redirect: { name: 'main' },
  meta: {
    menuTitle: 'Support',
  },
  children: [
    {
      path: '__', // external
      name: 'customer-support',
      redirect: '//support-maplestory.nexon.com/hc/en-us', // external
      meta: {
        menuTitle: 'Player Support',
      },
    },
    {
      path: 'server-status',
      name: 'server-status',
      component: () => import('@/views/support/ServerStatus/index.vue'),
      meta: {
        menuTitle: 'Server Status',
        subTitle: 'server stats at a glance',
        banners: ['news', 'player-ranking', 'bean-brigade'],
        seo: {
          description:
            'View the current status of MapleStory servers at a glance to know whether the game is up or down!',
        },
        gaPageType: 'information',
      },
      children: [
        {
          path: ':region?/:world?',
          name: 'server-status-world',
          component: () => import('@/views/support/ServerStatus/ServerStatus.vue'),
        },
      ],
    },
  ],
}

export default supportRoute
