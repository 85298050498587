// import { useWindowSize } from '@vueuse/core'
import type { App } from 'vue'
import { VisibilityState } from './observe-visibility'
import { deepEqual } from '@/helpers/utils'

// const thresholdForViewport = 0.3

export default {
  install: (app: App) => {
    const settings = (options: any) => ({
      intersection: { threshold: 0.3 },
      once: true,
      ...options,
    })
    app.directive('observable', {
      mounted(el, { value }) {
        // const { height: winHeight } = useWindowSize()
        // const { height } = useElementSize(el)
        // watchDebounced(
        //   [height, winHeight],
        //   () => {
        //     if (height.value > winHeight.value) {
        //       const newThreshold = (winHeight.value * thresholdForViewport) / height.value
        //       el._vue_visibilityState_threshold = newThreshold
        //       const state = el._vue_visibilityState
        //       state.createObserver(settings({ ...value, intersection: { threshold: newThreshold } }))
        //     }
        //   },
        //   { debounce: 100, maxWait: 400 }
        // )

        el._vue_visibilityState = new VisibilityState(el, settings(value))
      },
      updated(el, { value, oldValue }) {
        if (deepEqual(value, oldValue)) return
        const state = el._vue_visibilityState
        if (state) {
          state.createObserver(settings(value))
        }
      },
      unmounted(el) {
        const state = el._vue_visibilityState

        if (state) {
          state.destroyObserver()
          delete el._vue_visibilityState
        }
      },
    })
  },
}
