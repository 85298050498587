import { createRouter, createWebHistory } from 'vue-router'
// import { getLocale, setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from '@/i18n'

// import i18n from '@/i18n'
import routes from './routes'

// const locale = getLocale(i18n) // default locale

// create router instance
const router = createRouter({
  history: createWebHistory(`/${import.meta.env.VITE_APP_SERVICE_NAME}`),
  routes,
  // reference: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    // If you want to simulate the "scroll to anchor" behavior:
    if (to.hash) {
      return {
        el: to.hash,
        top: 100,
        behavior: 'smooth', // If your browser supports scroll behavior, you can make it smooth:
      }
    }
    // Returning the savedPosition will result in a native-like behavior when navigating with back/forward buttons
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.meta.menuTitle === from.meta.menuTitle) {
        const filterBoxEl = document.getElementsByClassName('filterbox-shadow')[0] as HTMLElement

        if (filterBoxEl) {
          if (filterBoxEl.offsetTop < window.scrollY - 45)
            if (!to.params.noScrollTop) filterBoxEl.scrollIntoView({ behavior: 'smooth', block: 'start' })
          return // no scroll within same filter page and it's in top
        } else {
          if (!to.meta.noscroll) window.scrollTo(0, 0)
        }
      }

      if (!to.meta.noscroll) window.scrollTo(0, 0)
    }
  },
})

// navigation guards
// router.beforeEach((to, from, next) => {
// carryover querystring
// const coQueryKeys = [
//   ...to.matched.reduce(
//     (prev, cur) => (cur.meta.coQuery ? [...new Set([...prev, ...cur.meta.coQuery])] : prev),
//     [] as string[]
//   ),
// ]
// const toQueryKeys = Object.keys(to.query)
// const fromQuery = filterObject(from.query, ([k, v]) => !!v && coQueryKeys.includes(k) && !toQueryKeys.includes(k))

// if (Object.keys(fromQuery).length > 0) {
//   return next({ ...to, query: { ...fromQuery, ...to.query } })
// }
///

// const paramsLocale = to.params.locale as string

// // use locale if paramsLocale is not in SUPPORT_LOCALES
// if (!SUPPORT_LOCALES.includes(paramsLocale)) {
//   return `/${locale}`
// }

// // load locale messages
// if (!i18n.global.availableLocales.includes(paramsLocale)) {
//   await loadLocaleMessages(i18n, paramsLocale)
// }

// // set i18n language
// setI18nLanguage(i18n, paramsLocale)
// if (to.matched.some((record) => record.meta.requiresAuth)) {
// }
//   next()
// })

export default router
