import { useGAEventStore } from '@/store/GAEventTracking'
import type { App } from 'vue'

export default {
  install: (app: App) => {
    const store = useGAEventStore()

    app.directive('ga-swiper-dot', {
      mounted(el, { value }) {
        nextTick(() => {
          const dots = el.querySelector('.swiper-pagination').childNodes
          dots.forEach((element: any, index: number) => {
            element.addEventListener('click', () => {
              store.pushActionEvent(
                {
                  interaction: 'carousel_click',
                  sectionContent: value ?? `Dot: ${index + 1}`,
                },
                el
              )
            })
          })
        })
      },
    })
  },
}
