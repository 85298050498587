<script setup lang="ts">
  import { lazyLoadComponent } from '@/helpers/lazyLoadComponent'
  import { onMounted, watch } from 'vue'
  import { gsap } from 'gsap'
  const MediaPlatformLinks = lazyLoadComponent({
    comp: () => import('@/components/Home/MediaPlatformLinks.vue'),
  })
  const route = useRoute()

  const tl = gsap.timeline()

  watch(
    () => route.meta.menuTitle,
    () => {
      gsap.from('.menu-title', { opacity: 0, y: '50%', stagger: 0.2, clearProps: true })
    }
  )
  onMounted(() => {
    tl.from('.menu-title', { opacity: 0, y: '50%', stagger: 0.2, clearProps: true }, '<50%')
  })
</script>
<template>
  <div v-if="!$route.meta.fullPage" class="main-route-view" :class="[$route.matched.map((x) => x.name)]">
    <ContentWrapper>
      <div class="page-header">
        <div class="header-image" />
        <div>
          <h1 class="menu-title">{{ $route.meta.menuTitle }}</h1>
          <h2 v-show="$route.meta.subTitle" class="xs menu-title text-uppercase">{{ $route.meta.subTitle }}</h2>
        </div>
      </div>
      <RouterView />
    </ContentWrapper>
    <PageBanners />
  </div>
  <RouterView v-else />
  <MediaPlatformLinks />
</template>

<style lang="scss" scoped>
  .main-route-view {
    padding-bottom: 40px;
    min-height: 50vw;
    background-size: 100% auto;
    background-repeat: no-repeat;
    // just for temporary default setting

    background-color: #f2f2f2;
    background-image: url('@/assets/imgs/page-backgrounds/rankings.webp');
    .page-header {
      position: relative;
      width: 100%;
      aspect-ratio: 100/14;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1em;
      order: 1;
      .header-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background: no-repeat calc(100% - 20px) calc(100% + 8px) / 55% auto;
      }
      h1,
      h2 {
        color: white;
        margin: 0;
        // text-shadow: 0 0 4px black;
      }
      h2 {
        font-family: 'Glegoo';
        line-height: 1.2em;
        display: inline-block;
        backdrop-filter: blur(10px);
        border-radius: 10px;
        padding: 0 4px;
      }
    }
    .header-image {
      background-image: url('@/assets/imgs/page-backgrounds/rankings-header.webp');
    }

    &.rankings {
      background-image: url('@/assets/imgs/page-backgrounds/rankings.webp');
      .header-image {
        background-image: url('@/assets/imgs/page-backgrounds/rankings-header.webp');
      }
    }
    &.news,
    &.general-post {
      background-image: url('@/assets/imgs/page-backgrounds/news.webp');
      .header-image {
        background-image: url('@/assets/imgs/page-backgrounds/news-header.webp');
      }
    }
    &.community {
      background-image: url('@/assets/imgs/page-backgrounds/community.webp');
      .header-image {
        background-image: url('@/assets/imgs/page-backgrounds/community-header.webp');
      }
    }
    &.support {
      background-image: url('@/assets/imgs/page-backgrounds/support.webp');
      .header-image {
        background-image: url('@/assets/imgs/page-backgrounds/support-header.webp');
      }
    }
    &.game {
      background-image: url('@/assets/imgs/page-backgrounds/game.webp');
      .header-image {
        background-image: url('@/assets/imgs/page-backgrounds/game-header.webp');
      }
    }
    &.maple-lane {
      background-image: url('@/assets/imgs/page-backgrounds/maple-lane.webp');
      background-color: #c7dc5c;
      .header-image {
        background: no-repeat 100% 100% / 35% auto;
        background-image: url('@/assets/imgs/page-backgrounds/maple-lane-header.webp');
      }
    }
  }
  #main-menu-body {
    font-size: 18px;
  }

  @media #{$tablet} {
    .page-header {
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 18px;
      }
    }
  }

  @media #{$mobile} {
    .main-route-view {
      &.rankings {
        background-image: url('@/assets/imgs/page-backgrounds/rankings-mobile.webp');
      }
      &.news-main,
      &.general-post {
        background-image: url('@/assets/imgs/page-backgrounds/news-mobile.webp');
      }
      &.community {
        background-image: url('@/assets/imgs/page-backgrounds/community-mobile.webp');
      }
      &.support {
        background-image: url('@/assets/imgs/page-backgrounds/support-mobile.webp');
      }
      &.game {
        background-image: url('@/assets/imgs/page-backgrounds/game-mobile.webp');
        &.sixth-job-skills {
          background-image: url('@/assets/imgs/page-backgrounds/game-6th-job-skills.webp');
        }
      }
      &.maple-lane {
        background-image: url('@/assets/imgs/page-backgrounds/maple-lane-mobile.webp');
      }
    }
    .page-header {
      text-align: center;
      padding: 20px 0 20px;
      aspect-ratio: initial;
      h1,
      h2 {
        // -webkit-text-stroke: 0.2em rgba(0, 0, 0, 0.5);
        // paint-order: stroke;
      }
    }
    .header-image {
      display: none;
    }
    :deep(.content) {
      display: flex;
      flex-direction: column;
      .page-header {
        order: 0;
      }
    }
  }
</style>
