import { deepEqual } from '@/helpers/utils'
import { useGAEventStore } from '@/store/GAEventTracking'
import type { App } from 'vue'

export default {
  install: (app: App) => {
    const store = useGAEventStore()

    const init = ({ el, arg, value, modifiers }: { el: any; arg: string | undefined; value: any; modifiers: any }) => {
      if (!value) return
      el._vue_gaEventType = arg ?? 'click'
      el._vue_gaEventValue = value
      el.addEventListener(
        el._vue_gaEventType,
        () => {
          let value = el._vue_gaEventValue
          if (typeof value === 'string') {
            // value can be a string as a shorthand value
            value = {
              sectionContent: value,
            }
          }
          store.pushActionEvent(
            {
              interaction: 'button_click', // default value
              ...value,
            },
            el
          )
        },
        {
          once: modifiers.once ?? false,
          capture: true, // it's required for the redirection button click - please don't remove this!
        }
      )
    }

    app.directive('ga-event', {
      mounted(el, { arg, value, modifiers }) {
        init({ el, arg, value, modifiers })
      },
      updated(el, { value, oldValue }) {
        if (deepEqual(value, oldValue)) return
        el._vue_gaEventValue = value
      },
    })
  },
}
